import React, { lazy, useState } from "react";
import { useSelector } from "react-redux";
import { User } from "storefront/User";
import { GlobalState } from "storefront/GlobalState";
import { routeMatches } from "storefront/lib/route";
import useIsClient from "storefront/hooks/ClientRendering/useIsClient";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import Modal from "storefront/components/Modal";

const ViolationAcknowledger = lazy(
  () =>
    import(
      /* webpackChunkName: "ViolationAcknowledger" */
      "./Acknowledger"
    ),
);

const userSelector = (state: GlobalState) => state.session.currentUser;

const ViolationModal = () => {
  const mounted = useIsClient();

  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const user = useSelector<GlobalState, User>(userSelector);

  const closeModal = () => setAcknowledged(true);

  if (mounted && routeMatches("/about/terms")) return null;

  return (
    <Modal
      className="jumbo"
      isOpen={!!user.hasUnacknowledgedViolations && !acknowledged}
      onRequestClose={closeModal}
      hideCloseButton
      preventCloseOnClickOutside
    >
      <SuspenseWithFragment>
        <ViolationAcknowledger currentUserId={user.id} onAccept={closeModal} />
      </SuspenseWithFragment>
    </Modal>
  );
};

export default ViolationModal;
