import React, { useEffect } from "react";
import useAnalytics from "storefront/hooks/useAnalytics";
import FlashManager from "storefront/lib/flash/FlashManager";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import SiteBanner from "storefront/components/SiteBanner";
import SuspenseWithLoading from "storefront/components/SuspenseWithLoading";
import ViolationModal from "storefront/components/ViolationModal";
import Contentful from "./Contentful";
import styles from "./index.module.scss";

const Homepage = () => {
  const { track } = useAnalytics();

  useEffect(() => {
    const flashManager = FlashManager.getInstance();
    const unsubscribe = requestWatcher.subscribe((requestKey) => {
      flashManager.notice(`${requestKey}:  Too many requests!`);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    track({
      object: "homepage",
      action: "viewed",
      properties: {
        nonInteraction: 1,
      },
    });
  }, [track]);

  return (
    <div id="homepage-v2">
      <div id="siteBanner">
        <SiteBanner />
      </div>

      <ViolationModal />

      <div className="homepage-wrapper">
        <SuspenseWithLoading className={styles.loading}>
          <Contentful />
        </SuspenseWithLoading>
      </div>
    </div>
  );
};

export default Homepage;
