import React from "react";
import Layout from "storefront/components/Page/Layout";
import Homepage from "storefront/components/Homepage";

const Page = () => {
  return (
    <Layout>
      <Homepage />
    </Layout>
  );
};

export default Page;
